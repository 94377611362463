<template>
    <section class="viviendas-viviendas position-relative">
        <cargando v-if="cargando" />
        <div class="col-12 my-2">
            <div class="row">
                <div class="col ml-0 pl-0">
                    <span class="f-20 f-500">Viviendas del sistema</span>
                </div>
                <div class="col-auto mx-2">
                    <div class="row bg-f5 border br-8 indicadores">
                        <div class="col-auto my-auto">
                            <p class="f-17 f-500">
                                {{ info.total_viviendas }}
                                <span class="f-100 pl-2 f-13 text-muted">Viviendas</span>
                            </p>
                        </div>
                        <div class="col pr-0">
                            <div class="indicador1 d-middle-center">
                                <i class="icon-house-outline op-05" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-auto mr-0">
                    <div class="row bg-f5 border br-8 indicadores">
                        <div class="col-auto my-auto">
                            <p class="f-17 f-500">
                                {{ info.total_viviendas_deuda }}
                                <span class="f-100 pl-2 f-13 text-muted">En Deuda</span>
                            </p>
                        </div>
                        <div class="col pr-0">
                            <div class="indicador2 d-middle-center">
                                <i class="f-15 icon-money-outline op-05" />
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-3 mr-0">
                    <el-input v-model="search" size="small" placeholder="Buscar vivienda" prefix-icon="el-icon-search" />
                </div>
            </div>
        </div>
        <!-- tabla -->
        <div class="row overflow-auto custom-scroll" style="height:calc(100vh - 206px)">
            <div class="col-12">
                <el-table :data="data" height="770" style="width: 100%" @row-click="verVivienda">
                    <el-table-column sortable prop="padre" label="Grupo de viviendas" min-width="60"> 
                        <template slot-scope="scope">
                            {{ scope.row.agrupacion }} <span v-show="scope.row.abreviatura">[{{ scope.row.abreviatura }}]</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="nombre" label="Vivienda" align="left" min-width="60" />
                    <el-table-column sortable prop="residentes_count" label="Residentes" width="120" align="center" />
                    <!-- <el-table-column label="Últ. Mes" prop="fecha_ultimo_pago" min-width="30">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="light" content="vencido a la fecha" :disabled="!scope.row.vencido || !scope.row.fecha_ultimo_pago" placement="bottom" :visible-arrow="false" popper-class="br-20 py-1 text-5d border-0 shadow">
                                <p :class="`py-1 text-center ${scope.row.vencido && scope.row.fecha_ultimo_pago ? 'bg-rojo-claro rounded-pill f-14' : ''}`"> {{ _.startCase(formatearFecha(scope.row.fecha_ultimo_pago,'MMMM YYYY')) }} </p>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="estado" label="Estado" min-width="80">
                        <template slot-scope="scope">
                            <div>
                                <span v-show="scope.row.estado == 0" class="bg-black br-4 px-2 py-1 f-14 text-white">Sin control de pagos</span> 
                                <span v-show="scope.row.estado == null" class="bg-pendiente br-4 px-2 py-1 f-14 text-muted">Sin registros</span>
                                <span v-show="scope.row.estado == 21" class="bg-pendiente br-4 px-2 py-1 f-14 text-muted">Sin verificar por administrador</span>
                                <span v-show="scope.row.estado == 31" class="bg-pendiente br-4 px-2 py-1 f-14 text-muted">Pendiente pago en línea</span>
                                <span v-show="scope.row.estado == 11" class="bg-verde-fuerte br-4 px-2 py-1 f-14 text-white">Pago por administrador</span>
                                <span v-show="scope.row.estado == 22" class="bg-verde-fuerte br-4 px-2 py-1 f-14 text-white">Pago por residente</span>
                                <span v-show="scope.row.estado == 32" class="bg-verde-fuerte br-4 px-2 py-1 f-14 text-white">Pago en línea</span>
                                <span v-show="scope.row.estado == 12" class="bg-azulclaro br-4 px-2 py-1 f-14 text-center">Paz y salvo</span>
                            </div>
                        </template>
                    </el-table-column> -->
                    <div slot="empty" class="row">
                        <div class="col-12 text-center">
                            <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120" />
                            <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No hemos encontrado coincidencias con tu búsqueda</p>
                        </div>
                    </div>
                </el-table>
            </div>
        </div>
    <!-- Partials -->
    </section>
</template>

<script>
import Viviendas from '~/services/viviendas'

export default {
    data(){
        return{
            viviendas: [],
            info:{},
            search: null,
            cargando: false
        }
    },
    computed: {
        data(){
            return this.viviendas.filter(data => !this.search || data.nombre.toLowerCase().includes(this.search.toLowerCase()))
        }
    },
    created(){
        this.estadisticas()
        this.listar()
    },
    methods:{
        verVivienda(row){
            this.$router.push({ name: 'viviendas.ver.residentes', params:{id_vivienda:row.id} })
        },
        async listar(){
            try {
                this.cargando = true
                const params={
                    search: this.search,
                }
                const {data} = await Viviendas.viviendasHijas(params)
                this.viviendas = data.data
            } catch (e){
                this.errorCatch(e)
            } finally {
                this.cargando = false
            }
        },
        async estadisticas(){
            try {
                this.cargando = true
                const {data} = await Viviendas.estadisticas()
                this.info = data.data
                  
            } catch (e){
                this.errorCatch(e)
            } finally {
                this.cargando = false
            }
        },

        buscador(search){
            this.search = search
            this.listar()
        }

    }
}
</script>

<style lang="scss" scoped>
    .viviendas-viviendas{
      .estado{
        &-pago{
          background: var(--color-db);
          color: var(--text-muted);
        }
        &-nopago{
          background: #FFB2C2;
          color: #FFFFFF;
        }
      }
      .indicadores{
          height: 32px;
          .indicador1, .indicador2{
                border-radius: 6px 8px 8px 0px;
                min-width: 38px;
                min-height: 100%;
                position: relative;
                i.icon-money-outline{
                    position:absolute;
                    top: 4px;
                    left: 5px;
                }
                
          }
      }
    }
    .text-sin-datos{
      color: var(--sin-datos);
      max-width: 286px;
      
    }
    .indicador1::before{
      width: 36px;
      height: 30px;
      position: absolute;
      background: var(--indicador-1);
      content: '';
      left: -9px;
      border-radius: 12px;
    }
    .indicador2::before{
      width: 36px;
      height: 30px;
      position: absolute;
      background: var(--indicador-2);
      content: '';
      left: -9px;
      border-radius: 12px;
    }
    
</style>